import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { HomeGuard } from './_guards/home.guard';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    runGuardsAndResolvers: 'always',
  },
  { 
    path: 'public', 
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: '',
    canActivate: [HomeGuard],
    canActivateChild: [HomeGuard],
    canLoad: [HomeGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserOnBehalfOfService } from '../home/_services/user-on-bahalf-of.service';
import { AuthTokenService } from '../_services/auth-token.service';

@Injectable()
export class UserOnBehalfOfInterceptor implements HttpInterceptor {

  constructor(
    private userOnBehalfOfService: UserOnBehalfOfService,
    private authTokensService: AuthTokenService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userId = this.userOnBehalfOfService.getUserId();

    if (this.authTokensService.isProviderAdmin() && userId) {
      request = request.clone({
        setHeaders: {
          UserOnBehalfOf: userId
        }
      });
    }

    return next.handle(request);
  }
}

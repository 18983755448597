import { Inject, Injectable } from "@angular/core";
import { ErrorHandlerOptions, SentryErrorHandler } from "@sentry/angular";

@Injectable({ providedIn: 'root' })
export default class AppErrorHandler extends SentryErrorHandler {
  public constructor(@Inject('errorHandlerOptions') options?: ErrorHandlerOptions) {
    super(options);
  }

  // If app changes, the chunk names will change on build and the old version will be removed from the dist folder. 
  // Once deployed, if a user is currently on the site, and then navigates to another part of the site, 
  // Sentry gets a "loading chunk failed" error because the old file is no longer there.
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      if (confirm("New version of the website is available. Would you like to reload the page?")) {
        window.location.reload();
        return;
      }
    }

    super.handleError(error);
  }
}
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { IUser } from '../_models/user';


@Injectable({ providedIn: 'root' })
export class UserOnBehalfOfService {
    constructor(
        private localStorageService: LocalStorageService) {
    }

    setUser(user: IUser): void {
        this.localStorageService.setItem('onBehalfOfUserId', user.id);
        this.localStorageService.setItem('onBehalfOfUserEmail', user.email);
        this.localStorageService.setItem('onBehalfOfUserFirstName', user.firstName);
        this.localStorageService.setItem('onBehalfOfUserLastName', user.lastName);
    }

    getUserId(): string {
        return this.localStorageService.getItem('onBehalfOfUserId');
    }

    getUserEmail(): string {
        return this.localStorageService.getItem('onBehalfOfUserEmail');
    }

    getUserFirstName(): string {
        return this.localStorageService.getItem('onBehalfOfUserFirstName');
    }

    getUserLastName(): string {
        return this.localStorageService.getItem('onBehalfOfUserLastName');
    }

    clear(): void {
        this.localStorageService.removeItem('onBehalfOfUserId');
        this.localStorageService.removeItem('onBehalfOfUserEmail');
        this.localStorageService.removeItem('onBehalfOfUserFirstName');
        this.localStorageService.removeItem('onBehalfOfUserLastName');
    }
}

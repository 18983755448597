import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { AuthTokens } from '../_models/auth-tokens';
import jwt_decode from 'jwt-decode';

const roleClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
const idClaim = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  constructor(private localStorageService: LocalStorageService) { }

  getAuthTokens(): AuthTokens {
    const authTokensString = this.localStorageService.getItem('authtokens');
    if (!authTokensString) {
      return null;
    }

    const authTokens = JSON.parse(atob(authTokensString)) as AuthTokens;
    if (authTokens && authTokens.accessToken) {
      return authTokens;
    }

    return null;
  }

  setAuthTokens(authTokens: AuthTokens): void {
    this.localStorageService.setItem('authtokens', btoa(JSON.stringify(authTokens)));
  }

  clearAuthTokens(): void {
    this.localStorageService.removeItem('authtokens');
  }

  isAdmin(): boolean {
    return this.isInRole('Admin');
  }

  isFinanceAdmin(): boolean {
    return this.isInRole('FinanceAdmin');
  }

  isProviderAdmin(): boolean {
    return this.isInRole('ProviderAdmin');
  }

  isDiscountAdmin(): boolean {
    return this.isInRole('DiscountAdmin');
  }

  isSuperAdmin(): boolean {
    return this.isInRole('SuperAdmin');
  }

  isFeedbackAdmin(): boolean {
    return this.isInRole('FeedbackAdmin');
  }

  is2FAAdmin(): boolean {
    return this.isInRole('2FAAdmin');
  }

  isOutOfStockAdmin(): boolean {
    return this.isInRole('OutOfStockAdmin');
  }

  getId(): string {
    const authTokens = this.getAuthTokens();

    if (!authTokens?.accessToken) {
      return null;
    }

    const decoded = this.getDecodedAccessToken(authTokens.accessToken);
    if (!decoded.hasOwnProperty(idClaim)) {
      return null;
    }

    return decoded[idClaim];
  }


  isInRole(role: string): boolean {
    const authTokens = this.getAuthTokens();
    if (!authTokens?.accessToken) {
      return false;
    }

    var roles = this.getRole(authTokens.accessToken);
    if (Array.isArray(roles)) {
      return roles.filter(r => r === role).length > 0;
    }

    return roles === role;
  }

  private getRole(token: string): string {
    const decoded = this.getDecodedAccessToken(token);
    if (!decoded.hasOwnProperty(roleClaim)) {
      return '';
    }

    return decoded[roleClaim];
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}
